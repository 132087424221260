import { Component, ElementRef } from '@angular/core';


import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'kids';
  data = {
    friend_email: ''
  }

  constructor(private http: HttpClient, private el: ElementRef) { }

  start(){
    const div = this.el.nativeElement.querySelector('#name');
    div.focus()
    console.log(' -- focus -- ')
  }

  sendEmail(registerForm: NgForm){
  
    let data ={
      email: registerForm.value.friend_email
    }

    this.http.post<any>('https://backoffice.familify.com/api/v1/crm/clients/', data).subscribe(data => {
      alert('Gracias por contar a tu amigo, nos contactaremos lo antes posible');
    })

  }


}

