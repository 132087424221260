<form role="form" id="register_form" class="php-email-form" (ngSubmit)="save(registerForm)" #registerForm="ngForm" (change)="calculate(registerForm)">

    <div class="form-row">
        <div class="col-6 align-items-center justify-content-center text-center"> <!--  -->
            <div class="parent mother-border #mother"
            (click)="setParentType('mother')"
            [ngClass]="{ 'parent-selected': parent.type.mother}"
            >
                <img src="assets/img/mother.png" alt="" class="face" />
                <div>Mamá</div>
            </div>
        </div>
        <div class="col-6 align-items-center justify-content-center text-center " (click)="setParentType('parent')"> <!--  -->
            <div class="parent father-border #father"
            (click)="setParentType('father')"
            [ngClass]="{ 'parent-selected': parent.type.father}"
            >
                <img src="assets/img/father.png" alt="" class="face" />
                <div>Papá</div>
            </div>
        </div>
    </div>

    <div class="form-row mt-5">
        <div class="form-group col-md-6">
            <label for="name">Nombre</label>
            <input type="text"
            class="form-control"
            id="name"
            [class.is-invalid]="name.invalid && name.touched"
            [ngModel]="parent.first_name"
            name="name"
            required
            minlength="3"
            #name="ngModel"
            />
            <div class="invalid-feedback" *ngIf="name.invalid && name.touched">Por favor ingrese al menos 3 caracteres</div>
        </div>
        <div class="form-group col-md-6">
            <label for="name">Apellido</label>
            <input type="text"
            class="form-control"
            id="last_name"
            [class.is-invalid]="last_name.invalid && last_name.touched"
            [ngModel]="parent.last_name"
            name="last_name"
            required
            minlength="3"
            #last_name="ngModel"
            />
            <div class="invalid-feedback" *ngIf="name.invalid && name.touched">Por favor ingrese al menos 3 caracteres</div>
        </div>
        <div class="form-group col-md-12">
            <label for="name">Correo eléctronico</label>
            <input type="email"
            class="form-control"
            id="email"
            data-rule="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
            [class.is-invalid]="email.invalid && email.touched"
            [ngModel]="parent.email"
            name="email"
            required
            #email="ngModel"
            />
            <div class="invalid-feedback" *ngIf="email.invalid && email.touched">Por favor ingrese un correo correcto</div>
        </div>
    </div>


    <div class="form-row">
        <div class="col-3 align-items-center justify-content-center text-center"> <!--  -->
            <div class="form-group">
                <label for="name">Código de país</label>
                <input type="text"
                class="form-control"
                id="country_code" 
                pattern="[0-9+]+"
                minlength="1"
                name="country_code"
                [class.is-invalid]="country_code.invalid && country_code.touched"
                [ngModel]="parent.country_code"
                required
                #country_code="ngModel"
                />
                <div class="invalid-feedback" *ngIf="phone.invalid && phone.touched">Por favor ingrese un Código correcto</div>
            </div>
        </div>
        <div class="col-9 align-items-center justify-content-center text-center " (click)="setParentType('parent')"> <!--  -->
            <div class="form-group">
                <label for="name">Número de <br />celular</label>
                <input type="text"
                class="form-control"
                id="phone" data-rule="minlen:6"
                pattern="[0-9]+"
                minlength="6"
                name="phone"
                [class.is-invalid]="phone.invalid && phone.touched"
                [ngModel]="parent.phone"
                required
                #phone="ngModel"
                />
                <div class="invalid-feedback" *ngIf="phone.invalid && phone.touched">Por favor ingrese un teléfono correcto</div>
            </div>
        </div>
    </div>


    <div class="form-group">
        <label for="name">Nombre de mi empresa</label>
        <input type="text"
        class="form-control"
        id="company"
        [class.is-invalid]="company.invalid && company.touched"
        [ngModel]="parent.company_name"
        name="company"
        #company="ngModel"
        />
        <div class="validate"></div>
    </div>

    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox"
        class="custom-control-input"
        id="opt_company"
        name="interested_company"
        [ngModel]="parent.interested_company"
        #interested_company="ngModel"
        />
        <label class="custom-control-label" for="opt_company">Me interesaría que la empresa cubra estos cursos</label>
    </div>

    <div class="form-row">
        <div class="form-group col-md-6">
            <label for="name">Nombre de tu hijo</label>
            <input type="text" class="form-control"
            id="name_child"
            name="name_child"
            [class.is-invalid]="name_child.invalid && name_child.touched"
            [ngModel]="parent.children[0].name"
            required
            minlength=5
            #name_child="ngModel"
            />
            <div class="invalid-feedback" *ngIf="name_child.invalid && name_child.touched">Por favor ingrese al menos 5 caracteres</div>
        </div>
        <div class="form-group col-md-6">
            <label for="name">Edad de tu hijo (años)</label>
            <input type="number"
            class="form-control"
            id="age"
            name="age_child"
            [class.is-invalid]="age_child.invalid && age_child.touched"
            [ngModel]="parent.children[0].age"
            required
            min="0"
            #age_child="ngModel"
            />
            <div class="invalid-feedback" *ngIf="age_child.invalid && age_child.touched">Por favor ingrese la edad</div>
        </div>
    </div>

    <div class="section-title">
        <h3>Selecciona las clases que quisieras que tenga tu hijo</h3>
    </div>

    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_01"
        name="games"
        [ngModel]="parent.classes.games"
        #games="ngModel"
        >
        <label class="custom-control-label" for="class_01"><strong>Juegos</strong><br/> Actividades entretenidas y dinámicas para hacer amigos</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_02"
        name="art"
        [ngModel]="parent.classes.art"
        #art="ngModel"
        />
        <label class="custom-control-label" for="class_02"><strong>Arte</strong><br/> Cada niño tiene su técnica y estilo, aquí la reforzamos</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_03"
        name="challenges"
        [ngModel]="parent.classes.challenges"
        #challenges="ngModel"
        />
        <label class="custom-control-label" for="class_03"><strong>Retos</strong><br/> divertidos ejercicios de matemáticas y lógica</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_04"
        name="zumba"
        [ngModel]="parent.classes.zumba"
        #zumba="ngModel"
        />
        <label class="custom-control-label" for="class_04"><strong>Zumba</strong><br/> kids divertidas clases de baile y ejercicio</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_05"
        name="english"
        [ngModel]="parent.classes.english"
        #english="ngModel"
        />
        <label class="custom-control-label" for="class_05"><strong>Inglés</strong><br/> Aprende inglés con juegos y actividades</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_06"
        name="taekwondo"
        [ngModel]="parent.classes.taekwondo"
        #taekwondo="ngModel"
        />
        <label class="custom-control-label" for="class_06"><strong>Taekwondo</strong><br/> Disciplina, deporte y concentración</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_07"
        name="manualidades"
        [ngModel]="parent.classes.manualidades"
        #manualidades="ngModel"
        />
        <label class="custom-control-label" for="class_07"><strong>Manualidades</strong><br/> Transforma objetos comunes en maravillosas obras de arte</label>
    </div>
    <div class="custom-control custom-checkbox checkbox-mgn">
        <input type="checkbox" class="custom-control-input" id="class_08"
        name="guitar"
        [ngModel]="parent.classes.guitar"
        #guitar="ngModel"
        />
        <label class="custom-control-label" for="class_08"><strong>Guitarra</strong><br/> Aprende este maravilloso instrumento</label>
    </div>

    <div class="accordion" id="accordionMonday">
        <div class="card">
            <div class="card-header" id="headingOne">
                <h2 class="mb-0">
                    <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseMonday" aria-expanded="true" aria-controls="collapseOne">
                        Lunes
                    </button>
                </h2>
            </div>

            <div id="collapseMonday" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionMonday">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="lunes_09"
                            name="day_monday_h9"
                            [ngModel]="parent.day.monday.h_9"
                            #day_monday_h9="ngModel"
                            value="monday_h9"
                            >
                            <label class="custom-control-label" for="lunes_09">09:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="lunes_11"
                            name="day_monday_h11"
                            [ngModel]="parent.day.monday.h_11"
                            #day_monday_h11="ngModel"
                            value="monday_h11"
                            >
                            <label class="custom-control-label" for="lunes_11">11:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="lunes_13"
                            name="day_monday_h13"
                            [ngModel]="parent.day.monday.h_13"
                            #day_monday_h13="ngModel"
                            value="monday_h13"
                            >
                            <label class="custom-control-label" for="lunes_13">13:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="lunes_15"
                            name="day_monday_h15"
                            [ngModel]="parent.day.monday.h_15"
                            #day_monday_h15="ngModel"
                            value="monday_h15"
                            >
                            <label class="custom-control-label" for="lunes_15">15:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="lunes_17"
                            name="day_monday_h17"
                            [ngModel]="parent.day.monday.h_17"
                            #day_monday_h17="ngModel"
                            value="monday_h17"
                            >
                            <label class="custom-control-label" for="lunes_17">17:00</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="lunes_10"
                                name="day_monday_h10"
                                [ngModel]="parent.day.monday.h_10"
                                #day_monday_h10="ngModel"
                                value="monday_h10"
                                >
                                <label class="custom-control-label" for="lunes_10">10:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="lunes_12"
                                name="day_monday_h12"
                                [ngModel]="parent.day.monday.h_12"
                                #day_monday_h12="ngModel"
                                value="monday_h12"
                                >
                                <label class="custom-control-label" for="lunes_12">12:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="lunes_14"
                                name="day_monday_h14"
                                [ngModel]="parent.day.monday.h_14"
                                #day_monday_h14="ngModel"
                                value="monday_h14"
                                >
                                <label class="custom-control-label" for="lunes_14">14:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="lunes_16"
                                name="day_monday_h16"
                                [ngModel]="parent.day.monday.h_16"
                                #day_monday_h16="ngModel"
                                value="monday_h16"
                                >
                                <label class="custom-control-label" for="lunes_16">16:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="lunes_18"
                                name="day_monday_h18"
                                [ngModel]="parent.day.monday.h_18"
                                #day_monday_h18="ngModel"
                                value="monday_h18"
                                >
                                <label class="custom-control-label" for="lunes_18">18:00</label>
                            </div>
                        </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseMonday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div class="accordion" id="accordionTuesday">
        <div class="card">
            <div class="card-header" id="headingTwo">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTuesday" aria-expanded="false" aria-controls="collapseTwo">
                        Martes
                    </button>
                </h2>
            </div>
            <div id="collapseTuesday" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionTuesday">
                <div class="card-body">

                <div class="row">
                    <div class="col-sm-6 col-6">
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_09"
                            name="day_tuesday_h9"
                            [ngModel]="parent.day.tuesday.h_9"
                            #day_tuesday_h9="ngModel"
                            value="tuesday_h9"
                            >
                            <label class="custom-control-label" for="martes_09">09:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_11"
                            name="day_tuesday_h11"
                            [ngModel]="parent.day.tuesday.h_11"
                            #day_tuesday_h11="ngModel"
                            value="tuesday_h11"
                            >
                            <label class="custom-control-label" for="martes_11">11:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_13"
                            name="day_tuesday_h13"
                            [ngModel]="parent.day.tuesday.h_13"
                            #day_tuesday_h13="ngModel"
                            value="tuesday_h13"
                            >
                            <label class="custom-control-label" for="martes_13">13:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_15"
                            name="day_tuesday_h15"
                            [ngModel]="parent.day.tuesday.h_15"
                            #day_tuesday_h15="ngModel"
                            value="tuesday_h15"
                            >
                            <label class="custom-control-label" for="martes_15">15:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_17"
                            name="day_tuesday_h17"
                            [ngModel]="parent.day.tuesday.h_17"
                            #day_tuesday_h17="ngModel"
                            value="tuesday_h17"
                            >
                            <label class="custom-control-label" for="martes_17">17:00</label>
                        </div>
                    </div>
                    <div class="col-sm-6 col-6">
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_10"
                            name="day_tuesday_h10"
                            [ngModel]="parent.day.tuesday.h_10"
                            #day_tuesday_h10="ngModel"
                            value="tuesday_h10"
                            >
                            <label class="custom-control-label" for="martes_10">10:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_12"
                            name="day_tuesday_h12"
                            [ngModel]="parent.day.tuesday.h_12"
                            #day_tuesday_h12="ngModel"
                            value="tuesday_h12"
                            >
                            <label class="custom-control-label" for="martes_12">12:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_14"
                            name="day_tuesday_h14"
                            [ngModel]="parent.day.tuesday.h_14"
                            #day_tuesday_h14="ngModel"
                            value="tuesday_h14"
                            >
                            <label class="custom-control-label" for="martes_14">14:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_16"
                            name="day_tuesday_h16"
                            [ngModel]="parent.day.tuesday.h_16"
                            #day_tuesday_h16="ngModel"
                            value="tuesday_h16"
                            >
                            <label class="custom-control-label" for="martes_16">16:00</label>
                        </div>
                        <div class="custom-control custom-checkbox checkbox-mgn">
                            <input type="checkbox" class="custom-control-input" id="martes_18"
                            name="day_tuesday_h18"
                            [ngModel]="parent.day.tuesday.h_18"
                            #day_tuesday_h18="ngModel"
                            value="tuesday_h18"
                            >
                            <label class="custom-control-label" for="martes_18">18:00</label>
                        </div>
                    </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseTuesday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div class="accordion" id="accordionWenesday">
        <div class="card">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseWenesday" aria-expanded="false" aria-controls="collapseThree">
                        Miércoles
                    </button>
                </h2>
            </div>
            <div id="collapseWenesday" class="collapse" aria-labelledby="headingThree" data-parent="#accordionWenesday">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_09"
                                name="day_wenesday_h9"
                                [ngModel]="parent.day.wenesday.h_9"
                                #day_wenesday_h9="ngModel"
                                value="wenesday_h9"
                                >
                                <label class="custom-control-label" for="miercoles_09">09:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_11"
                                name="day_wenesday_h11"
                                [ngModel]="parent.day.wenesday.h_11"
                                #day_wenesday_h11="ngModel"
                                value="wenesday_h11"
                                >
                                <label class="custom-control-label" for="miercoles_11">11:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_13"
                                name="day_wenesday_h13"
                                [ngModel]="parent.day.wenesday.h_13"
                                #day_wenesday_h13="ngModel"
                                value="wenesday_h13"
                                >
                                <label class="custom-control-label" for="miercoles_13">13:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_15"
                                name="day_wenesday_h15"
                                [ngModel]="parent.day.wenesday.h_15"
                                #day_wenesday_h15="ngModel"
                                value="wenesday_h15"
                                >
                                <label class="custom-control-label" for="miercoles">15:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_17"
                                name="day_wenesday_h17"
                                [ngModel]="parent.day.wenesday.h_17"
                                #day_wenesday_h17="ngModel"
                                value="wenesday_h17"
                                >
                                <label class="custom-control-label" for="miercoles_17">17:00</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_10"
                                name="day_wenesday_h10"
                                [ngModel]="parent.day.wenesday.h_10"
                                #day_wenesday_h10="ngModel"
                                value="wenesday_h10"
                                >
                                <label class="custom-control-label" for="miercoles_10">10:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_12"
                                name="day_wenesday_h12"
                                [ngModel]="parent.day.wenesday.h_12"
                                #day_wenesday_h12="ngModel"
                                value="wenesday_h12"
                                >
                                <label class="custom-control-label" for="miercoles_12">12:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_14"
                                name="day_wenesday_h14"
                                [ngModel]="parent.day.wenesday.h_14"
                                #day_wenesday_h14="ngModel"
                                value="wenesday_h14"
                                >
                                <label class="custom-control-label" for="miercoles_14">14:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_16"
                                name="day_wenesday_h16"
                                [ngModel]="parent.day.wenesday.h_16"
                                #day_wenesday_h16="ngModel"
                                value="wenesday_h16"
                                >
                                <label class="custom-control-label" for="miercoles_16">16:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="miercoles_18"
                                name="day_wenesday_h18"
                                [ngModel]="parent.day.wenesday.h_18"
                                #day_wenesday_h18="ngModel"
                                value="wenesday_h18"
                                >
                                <label class="custom-control-label" for="miercoles_18">18:00</label>
                            </div>
                        </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseWenesday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div class="accordion" id="accordionThursday">
        <div class="card">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThursday" aria-expanded="false" aria-controls="collapseThree">
                        Jueves
                    </button>
                </h2>
            </div>
            <div id="collapseThursday" class="collapse" aria-labelledby="headingThree" data-parent="#accordionThursday">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_09"
                                name="day_thursday_h9"
                                [ngModel]="parent.day.thursday.h_9"
                                #day_thursday_h9="ngModel"
                                value="thursday_h9"
                                >
                                <label class="custom-control-label" for="jueves_09">09:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_11"
                                name="day_thursday_h11"
                                [ngModel]="parent.day.thursday.h_11"
                                #day_thursday_h11="ngModel"
                                value="thursday_h11"
                                >
                                <label class="custom-control-label" for="jueves_11">11:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_13"
                                name="day_thursday_h13"
                                [ngModel]="parent.day.thursday.h_13"
                                #day_thursday_h13="ngModel"
                                value="thursday_h13"
                                >
                                <label class="custom-control-label" for="jueves_13">13:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_15"
                                name="day_thursday_h15"
                                [ngModel]="parent.day.thursday.h_15"
                                #day_thursday_h15="ngModel"
                                value="thursday_h15"
                                >
                                <label class="custom-control-label" for="jueves_15">15:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_17"
                                name="day_thursday_h17"
                                [ngModel]="parent.day.thursday.h_17"
                                #day_thursday_h17="ngModel"
                                value="thursday_h17"
                                >
                                <label class="custom-control-label" for="jueves_17">17:00</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_10"
                                name="day_thursday_h10"
                                [ngModel]="parent.day.thursday.h_10"
                                #day_thursday_h10="ngModel"
                                value="thursday_h10"
                                >
                                <label class="custom-control-label" for="jueves_10">10:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_12"
                                name="day_thursday_h12"
                                [ngModel]="parent.day.thursday.h_12"
                                #day_thursday_h12="ngModel"
                                value="thursday_h12"
                                >
                                <label class="custom-control-label" for="jueves_12">12:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_14"
                                name="day_thursday_h14"
                                [ngModel]="parent.day.thursday.h_14"
                                #day_thursday_h14="ngModel"
                                value="thursday_h14"
                                >
                                <label class="custom-control-label" for="jueves_14">14:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_16"
                                name="day_thursday_h16"
                                [ngModel]="parent.day.thursday.h_16"
                                #day_thursday_h16="ngModel"
                                value="thursday_h16"
                                >
                                <label class="custom-control-label" for="jueves_16">16:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="jueves_18"
                                name="day_thursday_h18"
                                [ngModel]="parent.day.thursday.h_18"
                                #day_thursday_h18="ngModel"
                                value="thursday_h18"
                                >
                                <label class="custom-control-label" for="jueves_18">18:00</label>
                            </div>
                        </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseThursday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div class="accordion" id="accordionFriday">
        <div class="card">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFriday" aria-expanded="false" aria-controls="collapseThree">
                        Viernes
                    </button>
                </h2>
            </div>
            <div id="collapseFriday" class="collapse" aria-labelledby="headingThree" data-parent="#accordionFriday">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_09"
                                name="day_friday_h9"
                                [ngModel]="parent.day.friday.h_9"
                                #day_friday_h9="ngModel"
                                value="friday_h9"
                                >
                                <label class="custom-control-label" for="viernes_09">09:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_11"
                                name="day_friday_h11"
                                [ngModel]="parent.day.friday.h_11"
                                #day_friday_h11="ngModel"
                                value="friday_h11"
                                >
                                <label class="custom-control-label" for="viernes_11">11:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_13"
                                name="day_friday_h13"
                                [ngModel]="parent.day.friday.h_13"
                                #day_friday_h13="ngModel"
                                value="friday_h13"
                                >
                                <label class="custom-control-label" for="viernes_13">13:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_15"
                                name="day_friday_h15"
                                [ngModel]="parent.day.friday.h_15"
                                #day_friday_h15="ngModel"
                                value="friday_h15"
                                >
                                <label class="custom-control-label" for="viernes_15">15:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_17"
                                name="day_friday_h17"
                                [ngModel]="parent.day.friday.h_17"
                                #day_friday_h17="ngModel"
                                value="friday_h17"
                                >
                                <label class="custom-control-label" for="viernes_17">17:00</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_10"
                                name="day_friday_h10"
                                [ngModel]="parent.day.friday.h_10"
                                #day_friday_h10="ngModel"
                                value="friday_h10"
                                >
                                <label class="custom-control-label" for="viernes_10">10:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_12"
                                name="day_friday_h12"
                                [ngModel]="parent.day.friday.h_12"
                                #day_friday_h12="ngModel"
                                value="friday_h12"
                                >
                                <label class="custom-control-label" for="viernes_12">12:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_14"
                                name="day_friday_h14"
                                [ngModel]="parent.day.friday.h_14"
                                #day_friday_h14="ngModel"
                                value="friday_h14"
                                >
                                <label class="custom-control-label" for="viernes_14">14:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_16"
                                name="day_friday_h16"
                                [ngModel]="parent.day.friday.h_16"
                                #day_friday_h16="ngModel"
                                value="friday_h16"
                                >
                                <label class="custom-control-label" for="viernes_16">16:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="viernes_18"
                                name="day_friday_h18"
                                [ngModel]="parent.day.friday.h_18"
                                #day_friday_h18="ngModel"
                                value="friday_h18"
                                >
                                <label class="custom-control-label" for="viernes_18">18:00</label>
                            </div>
                        </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseFriday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div class="accordion" id="accordionSaturday">
        <div class="card">
            <div class="card-header" id="headingThree">
                <h2 class="mb-0">
                    <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSaturday" aria-expanded="false" aria-controls="collapseThree">
                        Sábado
                    </button>
                </h2>
            </div>
            <div id="collapseSaturday" class="collapse" aria-labelledby="headingThree" data-parent="#accordionSaturday">
                <div class="card-body">
                    <div class="row">
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="sabado_09"
                                name="day_saturday_h9"
                                [ngModel]="parent.day.saturday.h_9"
                                #day_saturday_h9="ngModel"
                                value="saturday_h9"
                                >
                                <label class="custom-control-label" for="sabado_09">09:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="sabado_11"
                                name="day_saturday_h11"
                                [ngModel]="parent.day.saturday.h_11"
                                #day_saturday_h11="ngModel"
                                value="saturday_h11"
                                >
                                <label class="custom-control-label" for="sabado_11">11:00</label>
                            </div>
                        </div>
                        <div class="col-sm-6 col-6">
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="sabado_10"
                                name="day_saturday_h10"
                                [ngModel]="parent.day.saturday.h_10"
                                #day_saturday_h10="ngModel"
                                value="saturday_h10"
                                >
                                <label class="custom-control-label" for="sabado_10">10:00</label>
                            </div>
                            <div class="custom-control custom-checkbox checkbox-mgn">
                                <input type="checkbox" class="custom-control-input" id="sabado_12"
                                name="day_saturday_h12"
                                [ngModel]="parent.day.saturday.h_12"
                                #day_saturday_h12="ngModel"
                                value="saturday_h12"
                                >
                                <label class="custom-control-label" for="sabado_12">12:00</label>
                            </div>
                        </div>
                    </div><!-- row -->
                </div><!-- card-body -->
            </div><!-- collapseSaturday -->
        </div><!-- card -->
    </div> <!-- accordion -->

    <div>
        <div class="section-title">
            <h4>Todas las clases seleccionadas en la semana:</h4>
        </div>
    </div>


    <section id="contact" class="contact">
        <div class="container">


            <div class="row">
                <div class="col-12 d-flex align-items-center justify-content-center">
                    <div class="circle-class">
                        {{ total.hours }}
                        clases
                    </div>
                </div>
            </div>
            <div class="row text-center">
                <div class="col">

                </div>
            </div>
        </div>
    </section>


    <div>
        <div class="section-title">
            <h4>Ahorra al contratar por más tiempo</h4>
        </div>
    </div>

    <div> <!-- class="text-center" -->
        <!--div disabled class="monthly">
            <span class="btn-small">Ahorro en plan mensual</span>
            <br />
            <strong>${{ total.total_price }} – 30%</strong>
        </div-->


        <div class="custom-control custom-checkbox checkbox-mgn">
            <input type="checkbox"
            class="custom-control-input"
            id="discount_30"
            name="discount_30"
            [ngModel]="parent.discount_30"
            #discount_30="ngModel"
            (click)="getDiscount($event.target.checked, registerForm)"
            />
            <label class="custom-control-label" for="discount_30">Ahorrar 30% en el plan mensual</label>
        </div>

        <div class="custom-control custom-checkbox checkbox-mgn">
            <input type="checkbox"
            class="custom-control-input"
            id="unlimited_classes"
            name="unlimited_classes"
            [ngModel]="parent.unlimited_classes"
            #unlimited_classes="ngModel"
            (change)="unlimitedClasses($event.target.checked, registerForm)"
            />
            <label class="custom-control-label" for="unlimited_classes">Acceder a clases ilimitadas</label>
        </div>

        </div>
        <div class="text-center">

        <span class="badge badge-price">Precio final: {{ total.total_price }} USD /semana</span>


        <button type="submit" id="submit">
            <strong>REGISTRARME</strong>
        </button>

        <div>
            <p class="text-normal">
                ¿Preguntas? Escríbenos a <a href="mailto:francisco@familify.com">francisco@familify.com</a>
            </p>
        </div>

    </div>

</form>

