  <!-- ======= Header ======= -->
  <header id="header" class="fixed-top">
    <div class="container-fluid d-flex">

      <div class="logo mr-auto">
        <h1 class="text-light"><a href="index.html"><span><img alt="" src="assets/img/logo.png" /></span></a></h1>
        <!-- Uncomment below if you prefer to use an image logo -->
        <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
      </div>

      <nav class="nav-menu d-none d-lg-block">
        <ul>
          <!--li class="active"><a href="#header">Home</a></li>
          <li><a href="#about">About Us</a></li>
          <li><a href="#services">Services</a></li>
          <li><a href="#portfolio">Portfolio</a></li>
          <li><a href="#team">Team</a></li>
          <li class="drop-down"><a href="">Drop Down</a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="drop-down"><a href="#">Drop Down 2</a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
              <li><a href="#">Drop Down 5</a></li>
            </ul>
          </li>
          <li><a href="#contact">Contact Us</a></li-->

          <li class="get-started"><a href="#about">Registrarse</a></li>
        </ul>
      </nav><!-- .nav-menu -->

    </div>
  </header><!-- End Header -->

  <!-- ======= Hero Section ======= -->
  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-1 align-items-center justify-content-center"></div>
        <div class="col-10">
          <h1>Actividades Online para niños en casa</h1>
          <span class="badge badge-warning">#QuédateEnCasa</span>
          <h2>
            Mantén a tus hijos entretenidos en casa con actividades online en vivo. 
            Podrán aprender, 
            jugar e interactuar con otros niños de su edad desde la seguridad de tu casa.
          </h2>
          <a href="#register_form" class="btn-get-started scrollto btn-principal" (click)="start()">Registrarse</a>
        </div>
        <div class="col-1 align-items-center justify-content-center"></div>
      </div>
    </div>

  </section><!-- End Hero -->

  <main id="main">

    <!-- ======= About Section ======= -->
    <section id="about" class="about">
      <div class="container">

        <div class="row justify-content-between">
          <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
          <div class="col-lg-6 pt-5 pt-lg-0">
            <h3 >¡Escoge la actividad que más le gusta y tantas clases como quieran!</h3>
            <img src="assets/img/child.jpg" class="img-fluid" alt="">
            <p>
                Contamos con clases de baile, música, dibujo, matemáticas, deporte y más. 
                Cada clase es organizada en grupos limitados por edades (entre 4 y 11 años) y 
                dirigida por un profesional certificado y con experiencia validada en el 
                sistema educativo infantil. 
            </p>
          </div>
          <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
        </div>

      </div>
    </section><!-- End About Section -->

    <!-- ======= Services Section ======= -->
    <section id="services" class="services section-bg">
        <div class="container">
            <div class="row justify-content-between">
                <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
                <div class="col-lg-6 pt-5 pt-lg-0">
                    <div class="section-title">
                        <p>Tú y tus hijos amarán nuestras actividades:</p>
                    </div>
                    <div>

                      <div id="carouselExampleControls" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                          <div class="carousel-item active">
                              <div class="text-center justify-content-center align-items-center">
                                  <img src="assets/img/stars.png" alt="" class="review-stars" />
                                  <!--h6>¡Ama sus clases!</h6-->
                              </div>
                              <h5>Ha sido la solución definitiva para que pueda teletrabajar sin preocuparme de Luciana. ¡Le encantan sus clases!</h5>
                              <!--p class="review-sign"></p-->
                          </div>
                          <div class="carousel-item">
                              <div class="text-center align-items-center justify-content-center">
                                  <img src="assets/img/stars.png" alt="" class="review-stars" />
                                  <!--h6>¡Ama sus clases!</h6-->
                              </div>
                              
                              <h5>Por fin una actividad online que es educativa y que les permite interactuar con otros niños. Fabian está encantado con “sus amigos del iPad”</h5>
                              <!--p class="review-sign"></p-->
                          </div>
                          <div class="carousel-item">
                              <div class="text-center align-items-center justify-content-center">
                                  <img src="assets/img/stars.png" alt="" class="review-stars" />
                                  <!--h6>¡Ama sus clases!</h6-->
                              </div>
                              <h5>Agustín ama su clase de Karate y para Lucy no hay nada como bailar con su profe de Zumba. Se divierten, aprenden y hacen amigos. ¡Es lo máximo!</h5>
                              <!--p class="review-sign"></p-->
                          </div>
                        </div>
                        <a class="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev">
                          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                          <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                          <span class="carousel-control-next-icon" aria-hidden="true"></span>
                          <span class="sr-only">Next</span>
                        </a>
                      </div>

                        <!--p>
                        <strong>Familify Learning Kids</strong>
                        ha sido la solución definitiva para que pueda teletrabajar sin preocuparme de Luciana. 
                        Le encantan sus clases! 
                        </p>
                        <p>
                        Porfin una actividad online que es educativa y que les permite 
                        interactuar con otros niños.
                        Fabian está encantado con “sus amigos del iPad” 
                        </p>
                        <p>
                        Agustín ama su clase de Karate y para Lucy no hay nada como bailar con su profe de Zumba. 
                        Se divierten, aprenden y hacen amigos. ¡Es lo máximo! "
                        </p-->
                    </div>
                </div>
                <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
            </div>
        </div>
    </section><!-- End Services Section -->

   

    <section id="services" class="services section-bg">
      <div class="container">
          <div class="row justify-content-between">
              <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
              <div class="col-lg-6 pt-5 pt-lg-0">
                <img src="assets/img/calendar.png" class="img-fluid" alt="">
              </div>
              <div class="col-lg-3 d-flex align-items-center justify-content-center about-img"></div>
          </div>
      </div>
    </section><!-- End Services Section -->
   
    
   

    <!-- ======= Contact Us Section ======= -->
    <section id="contact" class="contact">
      <div class="container">
        <div class="section-title">
          <h2>¡No te quedes fuera!</h2>
          <p>Inscribe a tus hijos, ahora</p>
        </div>

        <div class="row">

          <div class="col-lg-3 d-flex align-items-stretch"></div>

          <div class="col-lg-6 mt-5 mt-lg-0 d-flex align-items-stretch">

            <router-outlet></router-outlet>

          </div>

          <div class="col-lg-3 d-flex align-items-stretch"></div>

        </div>

      </div>
    </section><!-- End Contact Us Section -->

  </main><!-- End #main -->

  <!-- ======= Footer ======= -->
  <footer id="footer">

    <div class="footer-newsletter">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <h4>¿Quieres que tu hijo asista a la actividad con un amigo? Recomiéndale tu mismo horario:</h4>
            <form (ngSubmit)="sendEmail(registerForm)" #registerForm="ngForm" >
              <input type="email" name="email"
              name="friend_email"
              required
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$"
              [ngModel]="data.friend_email"
              #friend_email="ngModel"
              >
              <input type="submit" value="ENVIAR">
            </form>
          </div>
        </div>
      </div>
    </div>

    <!--div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-3 col-md-6 footer-contact" data-aos="fade-up" data-aos-delay="100">
            <h3>Familify</h3>
            <p>
              A123 Calle 1 abc <br>
              Cuenca, EC 010107<br>
              Ecuador <br><br>
              <strong>Phone:</strong> +593 0999676767 55<br>
              <strong>Email:</strong> info@familify.com<br>
            </p>
          </div>

          <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="200">
            <h4>Useful Links</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Home</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">About us</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Team</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">APPS</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="300">
            <h4>Our Services</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <a href="#">link 1</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Link 2</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Link 3</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Link 4</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Link 5</a></li>
            </ul>
          </div>

          <div class="col-lg-3 col-md-6 footer-links" data-aos="fade-up" data-aos-delay="400">
            <h4>SOCIAL LINKS</h4>
            <p>bla bla bla bla bla</p>
            <div class="social-links mt-3">
              <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
              <a href="#" class="facebook"><i class="bx bxl-facebook"></i></a>
              <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
              <a href="#" class="pinterest"><i class="bx bxl-pinterest"></i></a>
              <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a>
            </div>
          </div>

        </div>
      </div>
    </div-->

    <div class="container py-4">
      <div class="copyright" style="padding: 20px;">
        &copy; Copyright <strong><span>Familify</span></strong>. All Rights Reserved
      </div>
      <div class="credits">
        <!-- All the links in the footer should remain intact. -->
        <!-- You can delete the links only if you purchased the pro version. -->
        <!-- Licensing information: https://bootstrapmade.com/license/ -->
        <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/ninestars-free-bootstrap-3-theme-for-creative/ -->
        <img alt="" src="assets/img/logo.png" class="img-fluid" style="max-width: 150px; height: auto;" />
      </div>
    </div>
  </footer><!-- End Footer -->

  <a href="#" class="back-to-top"><i class="icofont-simple-up"></i></a>



