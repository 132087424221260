import { Component, OnInit, ElementRef } from '@angular/core'; //OnChanges
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

  discount:number = 0;
  unlimited_classes:number = 50;
  total = {
    hours: 0,
    total_price: 0,
    discount_30: false,
    unlimited_classes: false
  }
  selected = 'mother';
  parent = {
    timezone: this.getTimezone(),
    type: {
      father:false,
      mother:true,
    },
    first_name: '',
    last_name: '',
    email: '',
    company_name: '',
    phone: '',
    country_code: '',
    interested_company: false,
    children:[{
      name: '',
      age: '',
    }],
    discount_30: false,
    unlimited_classes: false,
    classes:{
      games: false,
      art: false,
      challenges: false,
      zumba: false,
      english: false,
      taekwondo: false,
      manualidades: false,
      guitar: false,
    },
    day:{
      monday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false,
        h_13: false,
        h_14: false,
        h_15: false,
        h_16: false,
        h_17: false,
        h_18: false,
      },
      tuesday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false,
        h_13: false,
        h_14: false,
        h_15: false,
        h_16: false,
        h_17: false,
        h_18: false,
      },
      wenesday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false,
        h_13: false,
        h_14: false,
        h_15: false,
        h_16: false,
        h_17: false,
        h_18: false,
      },
      thursday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false,
        h_13: false,
        h_14: false,
        h_15: false,
        h_16: false,
        h_17: false,
        h_18: false,
      },
      friday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false,
        h_13: false,
        h_14: false,
        h_15: false,
        h_16: false,
        h_17: false,
        h_18: false,
      },
      saturday:{
        h_9: false,
        h_10: false,
        h_11: false,
        h_12: false
      }
    }
  }

  constructor(private http: HttpClient, private el: ElementRef) { }

  ngOnInit(): void {
  }

  calculate(registerForm: NgForm): void {

    this.parent = {
      timezone: this.getTimezone(),
      type: {
        father: this.parent.type.father,
        mother: this.parent.type.mother,
      },
      first_name: registerForm.value.name,
      last_name: registerForm.value.last_name,
      email: registerForm.value.email,
      company_name: registerForm.value.company,
      phone: registerForm.value.phone,
      country_code: registerForm.value.country_code,
      children:[{
        name: registerForm.value.name_child,
        age: registerForm.value.age_child,
      }],
      interested_company: registerForm.value.interested_company,
      discount_30: registerForm.value.discount_30,
      unlimited_classes: registerForm.value.unlimited_classes,
      classes:{
        games: registerForm.value.games,
        art: registerForm.value.art,
        challenges: registerForm.value.challenges,
        zumba: registerForm.value.zumba,
        english: registerForm.value.english,
        taekwondo: registerForm.value.taekwondo,
        manualidades: registerForm.value.manualidades,
        guitar: registerForm.value.guitar,
      },
      day:{
        monday:{
          h_9: registerForm.value.day_monday_h9,
          h_10: registerForm.value.day_monday_h10,
          h_11: registerForm.value.day_monday_h11,
          h_12: registerForm.value.day_monday_h12,
          h_13: registerForm.value.day_monday_h13,
          h_14: registerForm.value.day_monday_h14,
          h_15: registerForm.value.day_monday_h15,
          h_16: registerForm.value.day_monday_h16,
          h_17: registerForm.value.day_monday_h17,
          h_18: registerForm.value.day_monday_h18,
        },
        tuesday:{
          h_9: registerForm.value.day_tuesday_h9,
          h_10: registerForm.value.day_tuesday_h10,
          h_11: registerForm.value.day_tuesday_h11,
          h_12: registerForm.value.day_tuesday_h12,
          h_13: registerForm.value.day_tuesday_h13,
          h_14: registerForm.value.day_tuesday_h14,
          h_15: registerForm.value.day_tuesday_h15,
          h_16: registerForm.value.day_tuesday_h16,
          h_17: registerForm.value.day_tuesday_h17,
          h_18: registerForm.value.day_tuesday_h18,
        },
        wenesday:{
          h_9: registerForm.value.day_wenesday_h9,
          h_10: registerForm.value.day_wenesday_h10,
          h_11: registerForm.value.day_wenesday_h11,
          h_12: registerForm.value.day_wenesday_h12,
          h_13: registerForm.value.day_wenesday_h13,
          h_14: registerForm.value.day_wenesday_h14,
          h_15: registerForm.value.day_wenesday_h15,
          h_16: registerForm.value.day_wenesday_h16,
          h_17: registerForm.value.day_wenesday_h17,
          h_18: registerForm.value.day_wenesday_h18,
        },
        thursday:{
          h_9: registerForm.value.day_thursday_h9,
          h_10: registerForm.value.day_thursday_h10,
          h_11: registerForm.value.day_thursday_h11,
          h_12: registerForm.value.day_thursday_h12,
          h_13: registerForm.value.day_thursday_h13,
          h_14: registerForm.value.day_thursday_h14,
          h_15: registerForm.value.day_thursday_h15,
          h_16: registerForm.value.day_thursday_h16,
          h_17: registerForm.value.day_thursday_h17,
          h_18: registerForm.value.day_thursday_h18,
        },
        friday:{
          h_9: registerForm.value.day_friday_h9,
          h_10: registerForm.value.day_friday_h10,
          h_11: registerForm.value.day_friday_h11,
          h_12: registerForm.value.day_friday_h12,
          h_13: registerForm.value.day_friday_h13,
          h_14: registerForm.value.day_friday_h14,
          h_15: registerForm.value.day_friday_h15,
          h_16: registerForm.value.day_friday_h16,
          h_17: registerForm.value.day_friday_h17,
          h_18: registerForm.value.day_friday_h18,
        },
        saturday:{
          h_9: registerForm.value.day_saturday_h9,
          h_10: registerForm.value.day_saturday_h10,
          h_11: registerForm.value.day_saturday_h11,
          h_12: registerForm.value.day_saturday_h12
        }
      }
    }

    let count = 0;
    for(let day in this.parent.day){
        for(let hour in this.parent.day[day]){
          if(this.parent.day[day][hour] == true){
              count += 1
          }
        }
    }

    let total_price = 0;
    if(count < 10){
      total_price = count * 4;
    }
    if(count >= 10){
      total_price = count * 4;
    }

    // console.log('>>>>>', this.parent.discount_30 );
    // console.log('>>>>>', this.parent.unlimited_classes );
    //if( this.parent.discount_30 == true ){
      this.total = {
        hours: count,
        total_price: (total_price - (total_price * this.discount)),
        discount_30: false,
        unlimited_classes: false
      }
    //}
    if( this.parent.unlimited_classes == true ){
      this.total = {
        hours: count,
        total_price: 50,
        discount_30: false,
        unlimited_classes: false
      }
    }

    
  }

  getDiscount(discount_30, registerForm) {
    if (discount_30 == true) {
      this.discount = 0.3;
    } else {
      this.discount = 0;
    }
    this.calculate(registerForm);
    if(discount_30 == true){
      this.parent.discount_30 = true;
      this.parent.unlimited_classes = false;
    }
  }
  unlimitedClasses(unlimited_classes, registerForm){

    if(unlimited_classes == true){
      this.parent.discount_30 = false;
      this.parent.unlimited_classes = true;
      this.discount = 0;
      this.total.total_price = 50;
    }

  }


  save(registerForm: NgForm){
    const invalidControl = this.el.nativeElement.querySelector('#name');
    if(registerForm.invalid){
      invalidControl.focus();
      Object.values(registerForm.controls).forEach(control=>{
        control.markAsTouched();
      })
      return;
    }

    let data_parent = this.parent;
    if(this.parent.type.father){
      data_parent['gender'] = 'father';
    }else{
      data_parent['gender'] = 'mother';
    }
    data_parent['child_name'] = this.parent.children[0].name;
    data_parent['child_age'] = this.parent.children[0].age;

    data_parent['monday'] = this.parent.day.monday;
    data_parent['tuesday'] = this.parent.day.tuesday;
    data_parent['wednesday'] = this.parent.day.wenesday;
    data_parent['thursday'] = this.parent.day.thursday;
    data_parent['friday'] = this.parent.day.friday;
    data_parent['saturday'] = this.parent.day.saturday;

    this.http.post<any>('https://backoffice.familify.com/api/v1/forms/kids/', data_parent).subscribe(data => {
      alert('Gracias por registrarte. Nos contactaremos contigo pronto.');
    })

  }

  setParentType(parentType){
    if(parentType == 'mother'){
      this.parent.type.mother =  true;
      this.parent.type.father =  false;
      this.selected = 'mother';
    }
    if(parentType == 'father'){
      this.parent.type.mother =  false;
      this.parent.type.father =  true;
      this.selected = 'father';
    }
  }

  getTimezone(): number{
    let date = new Date();
    let timezone = date.getTimezoneOffset()
    if(timezone >= 0){
      return -(timezone/60)
    }else{
      return (timezone/60)
    }
  }

}
